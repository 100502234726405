<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-form>
        <b-card-code title="  تعديل سياسة">
          <b-row>

            <b-col
              md="4"
              xl="4"
            >
              <validation-provider
                #default="{ errors }"
                name="title"
                rules="required"
              >
                <b-form-group label-for="title">
                  <label> العنوان</label>
                  <b-form-input
                    v-model="title"
                  />
                </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>

            <b-col
              md="4"
              xl="4"
            >
              <validation-provider
                #default="{ errors }"
                name="type"
                rules="required"
              >
         
                <b-form-group label-for="type">
                  <label> النوع</label>
                  <v-select
                  v-model="type"
                  label="name"
                  :options="optionsType"
                  :reduce="(val) => val.id"
                />
                </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
                        <b-col
                       
              md="4"
              xl="4"
            >
              <validation-provider
                #default="{ errors }"
                name="department"
                rules="required"
              >
                            <b-form-group label-for="type">
                  <label> القسم</label>
                  <v-select
                  v-model="department"
                  :options="departmentOptions"
                  label="name"
                  :reduce="(val) => val.id"
                  multiple
                  :clearable="true"
                />
                </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col
              md="12"
              xl="12"
            >
              <validation-provider
                #default="{ errors }"
                name="description"
                rules=""
              >
                <b-form-group label-for="subject">
                  <label> الوصف</label>
                  <b-form-textarea
                    v-model="description"
                  />
                </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            
          
         
          </b-row>
          <!-- <b-row>
            <b-col cols="12">
          <b-form-group label="المحتوى" label-for="blog-content" class="mb-2">
            <quill-editor id="blog-content" v-model="description" :options="snowOption" />
          </b-form-group>
        </b-col>
          </b-row> -->
          
        
        
       
     
     
            
    
            <b-row>
              <b-col
                md="1"
                xl="3"
              >
                <b-button
                  variant="purple"
                  @click="updatePolicy"
                >
                  <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                  />
                  <span class="align-middle"> تعديل </span>
                </b-button>
              </b-col>
            </b-row>
          
        </b-card-code>
      </b-form>
    </validation-observer>
    <b-col md="4" xl="2">
      <b-button variant="purple " @click="$router.go(-1)">
        <span class="align-middle">رجوع</span>
      </b-button>
    </b-col>

  </div>
</template>

<script>
import 'vue-good-table/dist/vue-good-table.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BRow,

  BCol,
  BFormGroup,
  BInputGroupPrepend,
  BInputGroup,
  BDropdown,
  BDropdownItem,
  BDropdownItemButton,

  BFormDatepicker,
  BFormCheckbox,
  BFormInput,
  BFormTextarea,
  BFormFile,
  BButton,
  BForm,
  BFormInvalidFeedback,
  BPagination,
  BTable,
  BCardHeader, 
  BCardBody,
  BCard,
} from 'bootstrap-vue'

import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import { quillEditor } from "vue-quill-editor";
export default {
  name: 'AddSession',
  components: {
    flatPickr,
    BDropdown,
BCard,
    ToastificationContent,
    BDropdownItem,
    BDropdownItemButton,
    BFormInvalidFeedback,
    VueGoodTable,
    BForm,
    ValidationObserver,
    ValidationProvider,

    BCardCode,
    BButton,
    BFormInput,
    BFormFile,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    BFormDatepicker,

    BFormTextarea,
    vSelect,
    BFormCheckbox,
    BRow,
    BCol,
    BPagination,
  BTable,
  BCardHeader, 
  BCardBody,
  },



  data() {
    return {
title:'',
type:'',
description:'',
department:[],
optionsType:[
{id:2, name:"عامة"},
{id:1, name:"خاصة"},
],
departmentOptions : [],

    }
  },

  computed: {},

  created() {
    this.get();
    this.getDepartment()
  },

  methods: {
  
    getDepartment() {
      this.departmentOptions = []
      this.$http.get('/api/v1/departments').then(res => {
        // console.log('itemres',res);
        this.departmentOptions = res.data.data

        // console.log(this.optionitem);
      })
    },
    get() {
      this.options = [];
      this.$http.get(`/api/v1/policy/${this.$route.params.id}`).then((res) => {
        console.log(res.data.data);
        this.title = res.data.data.title;
        this.description=res.data.data.description;
        this.type=res.data.data.type;
        this.department = res.data.data.department
      });
    },
    updatePolicy() {
      const data = {
        title: this.title,
        description: this.description,
        type: this.type.id,
        department: this.department
      }
      console.log(data);
      let url=`/api/v1/policy/${this.$route.params.id}`
       this.$http.put(url,data).then((res) => {
          this.$swal({
            title: 'تم  التعديل بنجاح',

            icon: 'success',
            confirmButtonText: 'موافق',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
         this.subject = ''
       this.description= ''

        })
        .catch(error => {
          // console.log(error.response.data.message);
          this.$swal({
            title: `${error.response.data.message}`,

            icon: 'success',
            confirmButtonText: 'موافق',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
   },

   },
}
</script>

<style scoped>
label {
  color: rgba(112, 192, 203, 255);
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
.btn-purple {
  color: white;
  background-color: #7e7a9f;
  margin-top: 25px;
}

span {
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
.align-left {
  left: 50px;
}
.but {
  top: 40px;
}
</style>
